import Image from 'next/image';
import Link from 'next/link';
import { createComponent, IntrinsicProps, Slot } from 'react-commons';
import { DailyGameColor } from '@/lib/drupal/models/Games';

import style from './index.module.scss';

interface ImageUpsellPodProps extends IntrinsicProps {
  priority?: boolean
  imgSrc: string
  imgAlt: string
  href: string
  backgroundColor: DailyGameColor
}

export default
createComponent<ImageUpsellPodProps>('ImageUpsellPod', { style }, function WideText ({ className, slots }, props) {

  return (
    <div className={className}>
      <figure>
        <Image 
          src={props.imgSrc} 
          alt={props.imgAlt}
          layout='fill'
          priority={props.priority}
          aria-hidden
        />
      </figure>
      <section className={`--hasBackgroundColor__${props.backgroundColor}`}>
        { slots.textContent }
      </section>
    </div>
  );
});
 