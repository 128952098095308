import { ButtonLink, createComponent, If, IntrinsicProps, Slot } from 'react-commons';

import { FullGameData } from '@/lib/drupal/models/Games';
import PerDevice from '@/components/PerDevice';
import { dateToDailyFields } from '@/lib/hooks/useDailyArchive';
import { getZonedDate } from '@/lib/util/zonedTime';
import { UpsellArchiveTile } from '@/components/gamePage/Archive';
import { GridUpsellPod } from '@/components/dailyUpsellPods/GridUpsellPod';
import { getArchiveItemHref } from '@/lib/util/archiveHelpers';
import { CaptureClickEvent, CaptureClickEventContext } from '@/lib/util/captureClickEvent';
import { useContext } from 'react';

interface DailyArchiveUpsellTileProps extends IntrinsicProps {
  priority?: boolean
  game: FullGameData
  isPremiumUser?: boolean
}

export const DailyArchiveUpsellTile =
createComponent<DailyArchiveUpsellTileProps>('DailyArchiveUpsellTile', {  }, function DailyArchiveUpsellTile ({ className }, props) {
  const desktopItems = [];
  for (let i = 0; i < 6; i++) {
    const date = getZonedDate();
    date.setDate(date.getDate() - i);
    const fields = dateToDailyFields(date);

    const href = props.isPremiumUser ? getArchiveItemHref(fields, props.game.keyword, i === 0) : `${props.game.href}/archive`;

    desktopItems.push(
      <UpsellArchiveTile 
        item={{ fields }} 
        contentUrl={props.game.archive.contentUrl} 
        kind={props.game.isBonusGame ? 'bonus' : 'daily'} 
        hasPicture={props.game.archive.display === 'picture'}
        href={href}
      />
    );
  }

  const captureClickEventCtx = useContext(CaptureClickEventContext);

  return (
    <GridUpsellPod 
      backgroundColor={props.game.highlightColor} 
      rightText={props.isPremiumUser ? `With your subscription, you have access to more ${props.game.title}!` : `Play more ${props.game.title} in the archive with a subscription!`}
      items={desktopItems}
      mobileItem={desktopItems[ 1 ]} 
    >
      <Slot name='leftText'>
        <PerDevice useDisplay>
          <Slot name='mobile'>
            <h4 className='--marMd0__b'><strong>{props.game.title}</strong></h4>
            <p>{props.game.shortDescription}</p>
          </Slot>
          <Slot name='desktop'>
            <p>
              With {props.isPremiumUser ? 'your' : 'a'} subscription, you {props.isPremiumUser ? 'get' : 'can gain'} access to the entire catalog of daily game archives. From Daily Jigsaw to Daily Type, we've got you covered.
            </p>
          </Slot>
        </PerDevice>
      </Slot>
      <Slot name='leftButton'>
        <PerDevice useDisplay>
          <Slot name='mobile'>
            <ButtonLink href={`${props.game.href}`} rounded fullWidth medium className='--alignSelfFlexStart'>Play</ButtonLink>
          </Slot>
          <Slot name='desktop'>
            {
              If(props.isPremiumUser, () => (
                <ButtonLink href={`${props.game.href}/archive`} rounded medium className='--alignSelfFlexStart'>View Archive</ButtonLink>
              )).Else(() => (
                <CaptureClickEvent
                  action='upsell'
                  location='mid_page_upsell'
                  properties={() => ({
                    tag_name: 'upsell_photoarchive_teaser',
                    title: captureClickEventCtx.settings.gameTitle ?? '',
                    id: captureClickEventCtx.settings.gameId ?? '',
                  })}
                  href='/unlimited'
                >
                  <ButtonLink 
                    href='/unlimited' 
                    rounded medium 
                    className='upsell_photoarchive_teaser --alignSelfFlexStart'
                  >
                    Subscribe
                  </ButtonLink>
                </CaptureClickEvent>
              )).EndIf()
            }
          </Slot>
        </PerDevice>
      </Slot>
      <Slot name='rightButton'>
        {
          If(props.isPremiumUser, () => (
            <ButtonLink href={`${props.game.href}/archive`} rounded small>View Archive</ButtonLink>
          )).Else(() => (
            <CaptureClickEvent
              action='upsell'
              location='mid_page_upsell'
              properties={() => ({
                tag_name: 'upsell_photoarchive_teaser',
                title: captureClickEventCtx.settings.gameTitle ?? '',
                id: captureClickEventCtx.settings.gameId ?? '',
              })}
              href='/unlimited'
            >
              <ButtonLink 
                rounded small 
                href='/unlimited'
                className='upsell_photoarchive_teaser'
              >
                Subscribe
              </ButtonLink>
            </CaptureClickEvent>
          )).EndIf()
        }
      </Slot>
    </GridUpsellPod>
  );
});
