import { ReactElement } from 'react';
import { createComponent, Flex, For, If, IntrinsicProps, Slot } from 'react-commons';
import { DailyGameColor, FullGameData } from '@/lib/drupal/models/Games';
import PerDevice from '@/components/PerDevice';

import style from './index.module.scss';


interface GridUpsellPodProps extends IntrinsicProps {
  priority?: boolean
  backgroundColor: DailyGameColor
  rightText: string
  items: ReactElement[]
  mobileItem?: ReactElement
}

export const GridUpsellPod =
createComponent<GridUpsellPodProps>('GridUpsellPod', { style }, function GridUpsellPod ({ className, slots }, props) {

  const backgroundColor = (props.backgroundColor ?? 'yellowGreen') === 'white' ? 'yellowGreen' : props.backgroundColor;

  return (
    <div className={className}>
      <section className={`--hasBackgroundColor__${backgroundColor}`}>
        <Flex alignCenter className='GridUpsellPod--title'>
          <img 
            src='/images/shockwaveUnlimited.svg' 
            alt='Shockwave Unlimited'
            width='148'
            height='52'
          />
        </Flex>
        <div>
          { slots.leftText }
        </div>
        <Flex expand />
        { slots.leftButton }
      </section>
      <section>
        <Flex wide tall directionColumn>
          <PerDevice useDisplay>
            <Slot name='desktop'>
              <h4 className='GridUpsellPod__RightText'>{ props.rightText }</h4>

              <Flex tag='ul' expand gap0 wrap justifyCenter alignCenter>
                {
                  For(props.items, (item, i) => (
                    <li key={i}>
                      {item}
                    </li>
                  ))
                }
              </Flex>
            </Slot>
            <Slot name='mobile'>
              <Flex directionColumn tall alignCenter justifySpaceBetween>
                <h4 className='GridUpsellPod__RightText'>{ props.rightText }</h4>

                { 
                  If(props.mobileItem, () => (
                    <>
                      { props.mobileItem }
                    </>
                  )).Else(() => (
                    props.items[ 0 ]
                  )).EndIf()
                }

                { slots.rightButton }
              </Flex>
            </Slot>
          </PerDevice>
        </Flex>
      </section>
    </div>
  );
});
