import { ButtonLink, createComponent, Flex, IntrinsicProps, Slot } from 'react-commons';

import ImageUpsellPod from '../dailyUpsellPods/ImageUpsellPod';
import { CaptureClickEvent, CaptureClickEventContext } from '@/lib/util/captureClickEvent';
import { useContext } from 'react';

interface DownloadsUpsellTileProps extends IntrinsicProps {
  eventLocation: string
}

export default
createComponent<DownloadsUpsellTileProps>('DownloadsUpsellTile', { }, function DownloadsUpsellTile ({}, props) {
  const captureClickEventCtx = useContext(CaptureClickEventContext);

  return (
    <CaptureClickEvent
      action='upsell'
      location={props.eventLocation}
      properties={() => ({
        tag_name: 'upsell_download_teaser',
        title: captureClickEventCtx.settings.gameTitle ?? '',
        id: captureClickEventCtx.settings.gameId ?? '',
      })}
      href='/unlimited'
    >
      <ImageUpsellPod imgSrc='/images/unlimited/downloadUpsellThumb.png' imgAlt='Access Thousands of Downloadable Games' href='/unlimited' backgroundColor='lightBlue'>
        <Slot name='textContent'>
          <Flex directionColumn alignStart fill wide>
            <h3>Access Thousands of Downloadable Games</h3>
            <p>Why limit your gaming time when you can enjoy endless hours of entertainment? By subscribing to Shockwave, you gain access to thousands of downloadable games on your PC with absolutely no time restrictions.</p>
            <Flex fill />
            <ButtonLink 
              href='/unlimited' 
              rounded
              className='upsell_download_teaser'
            >
              Subscribe
            </ButtonLink>
          </Flex>
        </Slot>
      </ImageUpsellPod>   
    </CaptureClickEvent> 
  );
});
 