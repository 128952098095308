import { AdContainer, AdContainerSize, Container, If, IntrinsicProps, Slot, createComponent } from 'react-commons';

import { HeroGameTile, MobileHeroGameTile } from '../DailyGameTile';
import { HeroDailyGameTileContainer } from '../DailyGameTileContainer';
import PerDevice from '../PerDevice';
import { ExtendedGameData } from '@/lib/drupal/models/Games';
import { useIsPremiumUser } from '@/lib/hooks/useIsPremiumUser';

import style from './index.module.scss';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { useEffect, useRef, useState } from 'react';
import AdFallback from '../AdFallback';

export default createComponent('DailyHero', { style }, ({ className, slots }, props) => {
  return (
    <div className={className}>
      <Container>
        {props.children}
      </Container>
    </div>
  );
});

export const DailyHeroEmpty = createComponent('DailyHero', { style }, ({ className, slots }, props) => {
  return (
    <div className={className + ' DailyHero--empty'}>
      {props.children}
    </div>
  );
});

export interface DailyHeroGameTileProps extends IntrinsicProps {
  featuredGame: ExtendedGameData
  isPremiumUser: boolean
}

export const DailyHeroGameTile = createComponent<DailyHeroGameTileProps>('DailyHeroGameTile', { style }, ({}, props) => {

  const [ showAdFallback, setShowAdFallback ] = useState(false);

  return (
    <PerDevice useDisplay>
      <Slot name='mobile'>
        <MobileHeroGameTile 
          game={props.featuredGame} 
          priority 
          color='purple'
        />
      </Slot>
      <Slot name='desktop'>
        <HeroDailyGameTileContainer>
          <HeroGameTile 
            game={props.featuredGame}
            fullWidth={props.isPremiumUser}
            priority 
          />
        </HeroDailyGameTileContainer>
      </Slot>
    </PerDevice>
  );
});
