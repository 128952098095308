import { createComponent, IntrinsicProps } from 'react-commons';

import { ExtendedGameData } from '@/lib/drupal/models/Games';

import style from './index.module.scss';

interface DailyGameTileContainerProps extends IntrinsicProps {
  mobileSingleColumn?: boolean
  quad?: boolean
}

const classStates = [
  'mobileSingleColumn',
  'quad'
];

export const DailyGameTileContainer = 
createComponent<DailyGameTileContainerProps>('DailyGameTileContainer', { style, classStates }, function DailyGameTileContainer ({ className }, props) {
  return (
    <div className={className}>
      {props.children}
    </div>
  );
});

export const FeaturedDailyGameTileContainer = 
createComponent<DailyGameTileContainerProps>('DailyGameTileContainer', { style }, function FeaturedDailyGameTileContainer ({ className }, props) {
  return (
    <div className={className + ' DailyGameTileContainer--featured'}>
      {props.children}
    </div>
  );
});

export const CompactDailyGameTileContainer = 
createComponent<DailyGameTileContainerProps>('DailyGameTileContainer', { style }, function CompactDailyGameTileContainer ({ className }, props) {
  return (
    <div className={className + ' DailyGameTileContainer--compact'}>
      {props.children}
    </div>
  );
});

export const FlexDailyGameTileContainer = 
createComponent<DailyGameTileContainerProps>('DailyGameTileContainer', { style }, function CompactDailyGameTileContainer ({ className }, props) {
  return (
    <div className={className + ' DailyGameTileContainer--flex'}>
      {props.children}
    </div>
  );
});

export const HeroDailyGameTileContainer = 
createComponent<DailyGameTileContainerProps>('DailyGameTileContainer', { style }, function CompactDailyGameTileContainer ({ className }, props) {
  return (
    <div className={className + ' DailyGameTileContainer--hero'}>
      {props.children}
    </div>
  );
});
